import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { getRestaurantInfo, toggleCloseRestaurant } from '../../api/restaurantInfoApi'
import { useAuthContext } from '../../contexts/AuthContext'
import MenuList from './menuList/MenuList'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import MenuControls from './components/MenuControls'
import { useRequestStatus } from '../../hooks/useRequestStatus'
import VektorySyncButton from './components/VektorySyncButton'

function Menu(props: any) {
  const { t, i18n } = useTranslation()
  const [listView, setListView] = useState(false)
  const { loggedIn, user, isAdmin, isOwnerOf, isStaffAt } = useAuthContext()
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const { makeApiRequest, isFetching } = useRequestStatus()
  const [restaurantCloseConfirmOpen, setRestaurantCloseConfirmOpen] = useState(false)

  const isOwner = isOwnerOf(selectedRestaurant?.id)
  const isStaff = isStaffAt(selectedRestaurant?.id)

  const handleToggleCloseRestaurant = async () => {
    setRestaurantCloseConfirmOpen(false)
    const { resp } = await makeApiRequest(toggleCloseRestaurant, [
      selectedRestaurant?.id,
      !selectedRestaurant?.forceClosed,
    ])
    setSelectedRestaurant({ ...selectedRestaurant, forceClosed: resp?.data?.forceClosed })
  }

  const handleToggleCloseRestaurantConfirm = async () => {
    if (!selectedRestaurant?.forceClosed) {
      setRestaurantCloseConfirmOpen(true)
    } else {
      await handleToggleCloseRestaurant()
    }
  }

  return (
    <Stack p={2} pb={1} width="100%" height="100%" justifyContent="space-between">
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Stack spacing={1}>
            <Typography variant="h3"> {t('menuView.MenuViewTitle')}</Typography>
            <p>{t('menuView.MenuViewDescription')}</p>
          </Stack>

          {!isStaff && (
            <Stack direction="row" spacing={1}>
              <button
                className="btn-small right"
                onClick={e => {
                  setListView(false)
                }}
              >
                <i className="material-icons">view_list </i>
              </button>{' '}
              <button
                className="btn-small right"
                onClick={e => {
                  setListView(true)
                }}
              >
                <i className="material-icons">view_headline</i>
              </button>
            </Stack>
          )}
        </Stack>

        {selectedRestaurant && (
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <FormControlLabel
                checked={Boolean(selectedRestaurant?.forceClosed)}
                onChange={handleToggleCloseRestaurantConfirm}
                disabled={isFetching}
                control={<Switch classes={{ thumb: 'lever' }} />}
                label="Close Restaurant"
              />
              {!isStaff && <VektorySyncButton />}
            </Stack>

            {selectedRestaurant?.forceClosed && (
              <Alert severity="error">Your restaurant is closed and not accepting new orders</Alert>
            )}

            {isAdmin && !selectedRestaurant?.creds?.sumup_id && (
              <Alert severity="warning">Missing Sumup Credentials Kiosk will not connect</Alert>
            )}
          </Stack>
        )}

        <MenuList
          restaurantId={selectedRestaurant?.id}
          restaurant={selectedRestaurant}
          forceClosed={selectedRestaurant?.forceClosed}
          listView={listView}
          domElement={{}}
        />
      </Stack>

      <MenuControls />
      <Dialog open={restaurantCloseConfirmOpen} onClose={() => setRestaurantCloseConfirmOpen(false)}>
        <DialogContent>
          <Typography variant="h4">Are you sure that you want to close the restaurant?</Typography>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" color="error" onClick={handleToggleCloseRestaurant}>
              Close Restaurant
            </Button>
            <Button variant="contained" onClick={() => setRestaurantCloseConfirmOpen(false)}>
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default Menu
