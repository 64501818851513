import React, { useState } from 'react'
import { updateDevice } from '../../../../api/deviceApi'
import { useMenuContext } from '../../../../contexts/MenuContext'
import { timeAgo } from '../../../../utils/timeAgo'
import { SubMenuIdToName } from '../../SubMenuIdToName'
import PartialBackdrop from '../../../../ui/PatrialBackdrop/PartialBackdrop'
import { Button, Collapse, Stack } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import PrinterForm from '../../../../components/forms/PrinterForm'
import useYupValidationResolver from '../../../../hooks/useYupValidationResolver'
import { Printer } from '../../../../types'
import printerSchema from '../../../../schemas/printerSchema'
import DeleteConfirmButton from '../../../../ui/DeleteConfirmButton/DeleteConfirmButton'
import { RECOMMENDED_PRINTER_PRINTING_METHODS } from '../../../../utils/constants'

export default function DevicePrinterTableRow({ device, refresh, onUpdate, ...rest }: any) {
  // @ts-ignore
  const { allSubMenus } = useMenuContext()
  const [showForm, setShowForm] = useState(false)
  const [isSaving, setSaving] = useState(false)

  const resolver = useYupValidationResolver(printerSchema)
  const methods = useForm<Printer>({
    resolver,
    defaultValues: device,
  })

  const onSubmit = (data: any) => {
    // Setting model if only printing method provided
    const { model, printingMethod } = data
    if (printingMethod && !model) {
      const model = Object.keys(RECOMMENDED_PRINTER_PRINTING_METHODS).find(
        key => (RECOMMENDED_PRINTER_PRINTING_METHODS as any)?.[key] === printingMethod
      )
      console.log('model', model)

      methods.setValue('model', model)
    }

    onUpdate(device.id, data)
  }

  const handleCancelClick = () => {
    console.log('Cancel')
    setShowForm(false)
  }

  return (
    <>
      <tr>
        <td>{device.name}</td>
        <td>{device.type}</td>
        <td>{timeAgo(device.lastActivityAt || device.updatedAt)}</td>
        <td>
          {Array.isArray(device?.menuIds)
            ? device?.menuIds?.map((e: any) => SubMenuIdToName(e))?.join(', ')
            : device?.menuIds}
        </td>
        <td>
          <button className="btn" onClick={() => setShowForm(!showForm)}>
            Edit
          </button>
        </td>
      </tr>

      {showForm && (
        <tr className="edit-row row">
          <td colSpan={5}>
            <PartialBackdrop show={isSaving}>
              <Stack spacing={3} p={1}>
                <FormProvider {...methods}>
                  <PrinterForm />
                </FormProvider>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    variant="contained"
                    onClick={methods.handleSubmit(onSubmit, e => console.log('Submit failed: ', e))}
                  >
                    Save
                  </Button>
                  <Button variant="contained" color="info" onClick={handleCancelClick}>
                    Cancel
                  </Button>
                  <DeleteConfirmButton targetText="this Printer" onClick={() => rest.delete(device.id)}>
                    Delete kiosk
                  </DeleteConfirmButton>
                </Stack>
              </Stack>
            </PartialBackdrop>
          </td>
        </tr>
      )}
    </>
  )
}
