import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { acceptToS } from '../../api/me'
import { useAuthContext } from '../../contexts/AuthContext'
import MyntLogo from '../../images/MyntLogo.png'
import TosHtml from './tosHtml'

/**
 *
 */
function TermsOfService({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const { loggedIn } = useAuthContext()
  const navigate = useNavigate()
  const [restaurants, setRestaurants] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [accepted, setAccepted] = useState(false)

  const updateData = async () => {
    setLoaded(false)
    try {
    } catch (error) {
      setLoadingError(true) // TODO: enable error handling, no data returned.
    } finally {
      setLoaded(true)
    }
  }

  useEffect(() => {
    updateData()
  }, [loggedIn])

  const onAcceptTos = async () => {
    const resp = await acceptToS()
    if (resp?.success) {
      navigate('/', { replace: true })
    } else if (resp?.error) {
      alert(resp?.error)
    }
  }

  return (
    <>
      <div className="tos-container">
        <div className="row">
          <div className="col s12 top-part">
            <div className="topbar">
              <img src={MyntLogo} />
            </div>
            <div className="terms">
              <TosHtml />
            </div>
            <div className="buttonbar-container">
              <div className="buttonbar">
                <p>
                  <label>
                    <input
                      type="checkbox"
                      className="filled-in checkbox-mynt"
                      onChange={() => setAccepted(!accepted)}
                    />
                    <span>{t('tosView.buttonBar.accept')}</span>
                  </label>
                </p>
                <button className={accepted ? 'btn' : 'btn disabled'} onClick={onAcceptTos}>
                  {t('tosView.buttonBar.buttonContine')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsOfService
