import M from 'materialize-css'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getAuth } from 'firebase/auth'
import { useAuthContext } from '../../contexts/AuthContext'
import EditTimes from '../menu/restaurantInfo/RestaurantInfoEditModal/EditTimes/EditTimes'
import { useParams } from 'react-router-dom'
import { getRestaurantInfoById, saveRestaurantInfoData } from '../../api/restaurantInfoApi'
import CalendarOverride from '../../components/calendarOverride/CalendarOverride'
import BrandingIdentityEdit from './cards/BrandingIdentityEdit'
import KioskEditCard from './cards/KioskEdit'
import OrderScreenEdit from './cards/OrderScreenEdit'
import ScreensaverEdit from './cards/ScreensaverEdit'
import Loading from '../../components/loadingComponents/Loading'

/**
 *
 */
function BrandingView({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const [loaded, setLoaded] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [loadingErrorMessage, setLoadingErrorMessage] = useState('')
  const [restaurant, setRestaurant] = useState(null)
  let { id } = useParams()
  const { loggedIn } = useAuthContext()

  useEffect(() => {
    if (!loggedIn) return
    getRestaurantInfoById(id)
      .then(restaurantDataResp => {
        setRestaurant(restaurantDataResp?.data)
        setLoaded(true)
        setLoadingError(false)
      })
      .catch(error => {
        setLoadingError(true)
        setLoadingErrorMessage(error.message)
        // alert(error.message);
      })
  }, [loggedIn])

  useEffect(() => {}, [loaded])

  if (!loaded) {
    return <Loading />
  }

  if (loadingError) {
    return (
      <div className="error-wrapper">
        <div className="error-row">
          <i className="medium material-icons">error</i>
        </div>
        <div className="error-row">
          <h5>{t('general.ErrorLoading')}</h5>
          <p>{loadingErrorMessage}</p>
        </div>
      </div>
    )
  }

  console.log('res: ', restaurant)

  return (
    <div className="container branding-container">
      <div className="row">
        <div className="col s12 m12 l12">
          <h3>{t('brandingView.title')}</h3>
          <p>{t('brandingView.description')}</p>

          <BrandingIdentityEdit restaurant={restaurant} />

          <KioskEditCard restaurant={restaurant} />

          <OrderScreenEdit restaurant={restaurant} />

          <ScreensaverEdit restaurant={restaurant} />
        </div>
      </div>
    </div>
  )
}

export default BrandingView
