import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react'
import { getAuth, connectAuthEmulator, onAuthStateChanged } from 'firebase/auth'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getMe } from '../api/me'
import * as cache from '../utils/cache'
import { getRestaurantInfo, getRestaurantInfoById } from '../api/restaurantInfoApi'
import { getDevices } from '../api/deviceApi'
import { getRestaurantList } from '../api/restaurantListApi'
import { useRecoilState } from 'recoil'
import { restaurantListState } from '../atoms/RestaurantListAtom'
import { selectedRestaurantState } from '../atoms/SelectedRestaurantAtom'
import { devicesListState } from '../atoms/DevicesAtoms'
import { getTeamApi } from '../api/teamApi'
import { teamListState } from '../atoms/TeamsAtoms'
import { listCoupons } from '../api/adminApi'
import { couponListState } from '../atoms/CouponAtoms'
import Splash from '../components/Splash/Splash'
// import { useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react'
import { getReports } from '../api/report'
import { reportsListState } from '../atoms/ReportsAtoms'
import { getDatabase, onValue, ref } from 'firebase/database'
import { secondaryApp } from '../utils/firebase'
import { toast } from 'react-toastify'
import { getPartners } from '../api/partners'
import { partnersListState } from '../atoms/PartnersAtoms'

export const AuthContext = createContext<any>({})

export function AuthProvider(props: any) {
  const [loggedIn, setLoggedIn] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [user, setUser] = useState<any>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const [restaurantList, setRestaurantList] = useRecoilState(restaurantListState)
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const [updateHashes, setUpdateHashes] = useState<any>({})
  const [devicesList, setDevicesList] = useRecoilState(devicesListState)
  const [teamData, setTeamData] = useRecoilState<any>(teamListState)
  const [couponList, setCouponList] = useRecoilState(couponListState)
  const [reportsList, setReportsList] = useRecoilState(reportsListState)
  const [partnersList, setPartnersList] = useRecoilState(partnersListState)

  const database = getDatabase(secondaryApp)
  const selectedRestaurantDbRef = ref(database, `restaurants/${selectedRestaurant?.id}`)

  const isAdmin = useMemo(() => {
    const ADMINS = [
      'daniel+biz@reversely.fi',
      'emil+admin@sterly.io',
      'tuukka+admin@sterly.io',
      'arsenii@sterly.io',
    ].filter(x => x)
    return ADMINS.includes(user?.email)
  }, [user])

  const isOwnerOf = (restaurantId: string) => {
    return Boolean(user?.owningRestaurantIds?.includes(restaurantId))
  }
  const isStaffAt = (restaurantId: string) => {
    return Boolean(user?.workingRestaurantIds?.includes(restaurantId))
  }

  const isStaffAccount = !isAdmin && Boolean(user?.workingRestaurantIds?.length)

  const logOut = async () => {
    const auth = getAuth()
    await auth.signOut()
    setLoggedIn(false)
    navigate('/welcome', { replace: true })
  }

  useEffect(() => {
    const verifyUserLogin = async () => {
      const authToken = (await cache.get('store_auth_token')) || searchParams.get('auth_token')
      console.log('cache auth token', authToken)

      if (authToken) {
        cache.set('store_auth_token', authToken)
        const meResp = await getMe()
        setUser(meResp?.data)
        setLoggedIn(true)
      } else {
        console.log('checking auth')
        const auth = getAuth()
        console.log('auth', auth)
        onAuthStateChanged(auth, user => {
          console.log('user', user)
          if (user) {
            user.getIdToken().then(console.log)
            const uid = user.uid
            getMe()
              .then(meResp => {
                setUser(meResp?.data)
                setLoggedIn(true)
              })
              .catch(err => {
                console.error('error', err)
                switch (err?.response?.status) {
                  case 403:
                    navigate('/errors/account-not-connected')
                    break
                }
              })
          } else {
            logOut()
          }
        })
      }
    }

    verifyUserLogin()
  }, [searchParams])

  // Redirecting user to routes based on their account status
  useEffect(() => {
    if (isAdmin) return
    if (loggedIn) {
      if (!user?.tosAccepted) {
        // Need to test it
        navigate('/tos', { replace: true })
      } else if (user?.owningRestaurantIds?.length === 0 && user?.workingRestaurantIds?.length === 0) {
        navigate('/errors/account-not-connected')
      } else if (user?.owningRestaurantIds?.length) {
        getRestaurantInfo().then(({ data }) => {
          for (let i = 0; i < data?.length; i++) {
            const restaurant = data[i]
            console.log('restaurant', restaurant)
            getDevices().then(({ data }) => {
              const restaurantDevices = data.filter((device: any) => device.restaurantId === restaurant?.id)
              if (
                restaurant?.menu?.length === 0 &&
                (!restaurantDevices || restaurantDevices?.length == 0) &&
                !restaurant?.cmsOnboarded
              ) {
                console.log('onboarded: ', restaurant)
                if (!location.pathname.includes('onboarding')) navigate(`/onboarding/${restaurant?.id}`)
              }
            })
          }
        })
      } else {
        // console.log('Navigating to root')
        // navigate('/')
      }
    }
  }, [user])

  const updateRestaurantById = async (restaurantId: string) => {
    const { data } = await getRestaurantInfoById(restaurantId)

    setRestaurantList(oldList => {
      const updatedRestaurantList =
        oldList?.map(restaurant => {
          if (restaurant.id === restaurantId) {
            return data
          }
          return restaurant
        }) || []

      if (updatedRestaurantList?.length === 0) {
        updatedRestaurantList.push(data)
      }

      return updatedRestaurantList
    })

    return data
  }

  const fetchRestaurants = async (
    restaurantList: any[] | null,
    { onlyUpdate = [] }: { onlyUpdate?: string[] } | undefined = {}
  ) => {
    let restaurantListResp = []
    console.log('onlyUpdate', onlyUpdate)
    console.log('restaurantList', restaurantList)
    if (onlyUpdate.length && restaurantList?.length) {
      const promises = onlyUpdate.map(restaurantId => getRestaurantInfoById(restaurantId))
      const data = await Promise.all(promises)
      const joinedRestaurantList = restaurantList?.map(restaurant => {
        const updatedRestaurant = data.find(({ data }) => data.id === restaurant.id)?.data
        if (updatedRestaurant) {
          return updatedRestaurant
        }
        return restaurant
      })
      console.log('joinedRestaurantList', joinedRestaurantList)
      restaurantListResp = joinedRestaurantList || []
    } else {
      const { data } = await getRestaurantList()
      restaurantListResp = data
    }
    console.log('restaurantListResp', restaurantListResp)
    const alphabeticallySortedRestaurants = restaurantListResp.sort((a: any, b: any) => {
      // Convert names to lowercase to ensure case-insensitive sorting
      const nameA = a?.name?.toLowerCase()
      const nameB = b?.name?.toLowerCase()

      if (nameA < nameB) {
        return -1
      }

      if (nameA > nameB) {
        return 1
      }

      return 0
    })

    const deletedFiltered = alphabeticallySortedRestaurants
    const selectedRestaurantId = localStorage.getItem('selectedRestaurantId')
    const selectedRestaurant = deletedFiltered.find((restaurant: any) => restaurant.id === selectedRestaurantId)

    setRestaurantList(deletedFiltered)
    if (selectedRestaurant) {
      setSelectedRestaurant(selectedRestaurant)
    } else {
      setSelectedRestaurant(deletedFiltered[0])
    }
  }

  // Getting global state
  useEffect(() => {
    if (!user) return
    ;(async () => {
      // Getting selected restaurant first for better UX
      const selectedRestaurantId = localStorage.getItem('selectedRestaurantId')
      if (selectedRestaurantId) {
        const selectedRestaurantRes = await updateRestaurantById(selectedRestaurantId)
        setSelectedRestaurant(selectedRestaurantRes)
      }
    })()
    ;(async () => {
      await fetchRestaurants(null)
    })()
    ;(async () => {
      const { data } = await getDevices()
      setDevicesList(data)
    })()
    ;(async () => {
      const { data } = await getTeamApi()
      const alphabeticallySortedTeamList = data.sort((a: any, b: any) => {
        // Convert names to lowercase to ensure case-insensitive sorting
        const emailA = a?.email?.toLowerCase()
        const emailB = b?.email?.toLowerCase()
        if (emailA === '') return 1
        if (emailB === '') return -1
        if (emailA < emailB) {
          return -1
        }
        if (emailA > emailB) {
          return 1
        }
        return 0
      })
      setTeamData(alphabeticallySortedTeamList)
      console.log('teamData', alphabeticallySortedTeamList)
    })()
    ;(async () => {
      const { data } = await listCoupons()
      setCouponList(data)
      console.log('couponList', data)
    })()
    ;(async () => {
      const { data } = await getReports()
      setReportsList(data)
      console.log('reportsList', data)
    })()
    ;(async () => {
      const { data } = await getPartners()
      setPartnersList(data)
      console.log('partnersList', data)
    })()
  }, [user])

  console.log('selectedRestaurant', selectedRestaurant)

  useEffect(() => {
    return onValue(selectedRestaurantDbRef, async snapshot => {
      if (!snapshot.exists()) return

      const localUpdateHash = updateHashes?.[selectedRestaurant?.id]

      if (snapshot?.val()?.update_hash === localUpdateHash) return
      console.log('db hash: ', snapshot.val()?.update_hash, '\nstate hash: ', localUpdateHash)
      console.log("Selected restaurant's data has been updated")

      const updatedSeletedRestaurant = await updateRestaurantById(selectedRestaurant?.id)
      setSelectedRestaurant(updatedSeletedRestaurant)
      toast.dismiss()
      toast.info('Restaurant data updated')

      setUpdateHashes((oldHashes: any) => ({
        ...oldHashes,
        [selectedRestaurant?.id]: snapshot.val()?.update_hash,
      }))
    })
  }, [updateHashes, restaurantList, selectedRestaurant?.id])

  useEffect(() => {
    return onValue(ref(database, 'restaurants'), snapshot => {
      if (!snapshot.exists()) return
      const realTimeDbRestaurants = snapshot.val()
      const updateHashes: any = {}
      for (const restaurantId in realTimeDbRestaurants) {
        updateHashes[restaurantId] = realTimeDbRestaurants[restaurantId]?.update_hash
      }
      console.log('updateHashes', updateHashes)
      setUpdateHashes(updateHashes)
    })
  }, [])

  const noSplashLocations = ['/welcome', '/tos', '/onboarding', '/errors', '/errors/account-not-connected']
  const displaySplash = Boolean(!user) && !noSplashLocations.includes(location?.pathname)
  useEffect(() => {
    Sentry.setTag('User email', user?.email)
    Sentry.setTag('User id', user?.id)
  }, [user?.id])

  return (
    <AuthContext.Provider
      value={{ setLoggedIn, loggedIn, logOut, user, isAdmin, isOwnerOf, isStaffAt, isStaffAccount, fetchRestaurants }}
      {...props}
    >
      <Splash show={displaySplash} />
      {props.children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  return useContext(AuthContext)
}
