import React, { useEffect, Suspense, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil'
import Auth from './views/auth/Auth'
import Team from './views/team/Team'
import Stripe from './views/stripe/Stripe'
import TermsOfService from './views/tos/ToS'
import { useTranslation, withTranslation, Trans } from 'react-i18next'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import Menu from './views/menu/Menu'
import { AuthProvider } from './contexts/AuthContext'
import Orders from './views/orders/Orders'
import RestaurantConfigView from './views/restaurantConfigView/RestrauntConfigView'
import OpenTimes from './views/openTimes/OpenTimes'
import AdminCoupon from './views/adminCoupon/AdminCoupon'
import ReportsView from './views/reportsView/ReportsView'
import BrandingView from './views/brandingView/BrandingView'
import DeviceManagementView from './views/devices/DeviceManagementView'
import MultiMenu from './views/multiMenu/MultiMenu'
import AdminOrdersFlow from './views/adminOrdersFlow/AdminOrdersFlow'
import { MenuContextProvider } from './contexts/MenuContext'
import AdminConfigurations from './views/adminConfigurations/AdminConfigurations'
import CouponCodes from './views/couponCode/CouponCodes'
import { ThemeProvider } from '@emotion/react'
import theme from './theme'
import AccountNotConnected from './views/errors/accountNotConnected/AccountNotConnected'
import Step1 from './views/onboarding/steps/Step1'
import Onboarding from './views/onboarding/Onboarding'
import Step2 from './views/onboarding/steps/Step2'
import Step3 from './views/onboarding/steps/Step3'
import CurrentDevice from './views/currentDevice/CurrentDevice'
import Dashboard from './views/dashboard/Dashboard'
import Layout from './components/Layout/Layout'
import Identity from './views/identity/Identity'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Logs from './views/logs/Logs'
import { MenuSelectProvider } from './contexts/MenuSelectContext'
import { ErrorBoundary } from '@sentry/react'
import SentryErrorView from './views/sentryErrorView/SentryErrorView'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <ErrorBoundary
            fallback={({ error, componentStack, resetError }) => (
              <SentryErrorView error={error} componentStack={componentStack} />
            )}
          >
            <AuthProvider>
              <MenuContextProvider>
                <MenuSelectProvider>
                  <Routes>
                    <Route path="/welcome" element={<Auth />}></Route>
                    <Route path="tos" element={<TermsOfService domElement />}></Route>
                    <Route path="errors/account-not-connected" element={<AccountNotConnected />} />
                    <Route element={<Layout />}>
                      <Route path="/" element={<Menu domElement />}></Route>
                      <Route path="dashboard" element={<Dashboard />}></Route>
                      <Route path="menu" element={<Menu domElement />}></Route>
                      <Route path="identity" element={<Identity />}></Route>
                      <Route path="multiMenu" element={<MultiMenu />}></Route>
                      <Route path="team" element={<Team />}></Route>
                      <Route path="stripe" element={<Stripe domElement />}></Route>
                      <Route path="promos" element={<CouponCodes />}></Route>
                      <Route path="adminCoupon" element={<AdminCoupon domElement />}></Route>
                      <Route path="reports" element={<ReportsView />}></Route>
                      <Route path="orders" element={<Orders domElement />}></Route>
                      <Route path="config" element={<RestaurantConfigView />} />
                      <Route path="times" element={<OpenTimes />} />
                      <Route path="branding" element={<BrandingView domElement />} />
                      <Route path="adminOrders" element={<AdminOrdersFlow domElement />}></Route>
                      <Route path="devices" element={<DeviceManagementView />}></Route>
                      <Route path="devices/current" element={<CurrentDevice />} />
                      <Route path="adminConfigurations" element={<AdminConfigurations domElement />}></Route>
                      <Route path="logs" element={<Logs />}></Route>
                      <Route path="errors/account-not-connected" element={<AccountNotConnected />} />
                    </Route>
                    <Route path="/onboarding/:id" element={<Onboarding />}>
                      <Route index element={<Step1 />}></Route>
                      <Route path="/onboarding/:id/step1" element={<Step1 />}></Route>
                      <Route path="/onboarding/:id/step2" element={<Step2 />}></Route>
                      <Route path="/onboarding/:id/step3" element={<Step3 />}></Route>
                    </Route>
                  </Routes>
                  <ToastContainer theme={theme?.palette?.mode} position="bottom-right" />
                </MenuSelectProvider>
              </MenuContextProvider>
            </AuthProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </RecoilRoot>
    </ThemeProvider>
  )
  // <Suspense fallback={<Loader />}>
  //   <Auth setLoggedIn={setLoggedIn} />
  // </Suspense>
}
export default App
