import React from 'react'
import defaultBackgroundImage from '../../../images/defaultBg.jpg'
import defaultLogo from '../../../images/MyntWhite.png'
import defaultSplashImage from '../../../images/DefaultSplash.png'
import startLanguageImage from '../../../images/Start.png'
import transperentPixelImage from '../../../images/transperent.png'

export default function KioskPreview(props) {
  let backgroundImage = props.backgroundImage || defaultBackgroundImage
  let logo = props.logo || defaultLogo
  let splashImage = props.splashImage || defaultSplashImage

  if (logo == 'NONE') {
    logo = transperentPixelImage
  }
  if (splashImage == 'NONE') {
    splashImage = transperentPixelImage
  }

  console.log('LOGO IMAGE: ', logo)

  return (
    <div className="preview-wrapper" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <img src={logo} className="logo" />
      <img src={splashImage} className="splash" />
      <img src={startLanguageImage} className="start" />
    </div>
  )
}
