import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { FormProvider, useForm } from 'react-hook-form'
import { getAuth } from 'firebase/auth'
import { uploadFileJPEG } from '../../../../utils'
import useYupValidationResolver from '../../../../hooks/useYupValidationResolver'
import { itemSchema } from '../../../../schemas'
import Item from '../../../../types/Item'
import theme from '../../../../theme'
import useDeprecatedItemValues from '../../../../hooks/useDeprecatedItemValues'
import ItemForm from '../../../../components/forms/ItemForm/ItemForm'

type EditMenuItemProps = {
  open: boolean
  onClose: () => void
  restaurant: any
  onSave: (item: Item, id: string) => Promise<void>
  item: any
  resetOnClose?: boolean
}

function EditMenuItem({ open, onClose, restaurant, onSave, item, resetOnClose }: EditMenuItemProps) {
  const { t } = useTranslation()
  const [tab, setTab] = useState(1)
  const [specialAvailability, setSpecialAvailability] = useState(item.specialAvailability || {})
  const [isSubmitting, setSubmitting] = useState(false)

  const resolver = useYupValidationResolver(itemSchema)
  const methods = useForm<Item>({
    shouldUnregister: Boolean(resetOnClose),
    mode: 'all',
    resolver,
    defaultValues: item,
  })
  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = methods
  useDeprecatedItemValues(getValues, setValue)

  const onSubmit = async (item: Item) => {
    setSubmitting(true)

    const formatedSpecialAvailability = Object.keys(specialAvailability).length === 0 ? null : specialAvailability

    if (item.image) {
      const auth = await getAuth()
      const imagePath = `/users/${auth?.currentUser?.uid}/restaurants/${restaurant?.id}/images/${uuidv4()}.jpeg`
      const image = await uploadFileJPEG(item.image, imagePath)
      item = { ...item, specialAvailability: formatedSpecialAvailability, image }
    } else {
      item = { ...item, specialAvailability: formatedSpecialAvailability }
    }

    console.log('submit valid: ', item)

    await onSave(item, item.id)
    setSubmitting(false)
    onClose()
  }

  useEffect(() => {
    // Find tabs, where errors occured
    // There might be some better way of doing this
    let infoErrors = false
    let optionsErrors = false
    let extrasErrors = false

    Object.keys(errors).forEach(error => {
      if (error.includes('options')) optionsErrors = true
      else if (error.includes('extras')) extrasErrors = true
      else infoErrors = true
    })

    if (infoErrors) setTab(1)
    if (optionsErrors) setTab(2)
    if (extrasErrors) setTab(3)
  }, [errors])

  useEffect(() => {
    if (!open && resetOnClose) {
      reset(item)
    }
  }, [open])

  useEffect(() => {
    reset(item)
  }, [item])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        /* Weird scroll behavior without */
        '& > .MuiDialog-container > .MuiPaper-root': {
          overflowY: 'initial',
          width: '100vw',
          border: Object.keys(errors).length ? `2px solid ${theme.palette.error.main}` : 'none',
        },
        '& > .MuiDialog-container': { alignItems: 'flex-start' },
      }}
    >
      <DialogContent sx={{ overflowY: 'scroll' /* Weird scroll behavior without */ }}>
        <FormProvider {...methods}>
          <ItemForm restaurant={restaurant} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onSubmit, async data => console.log('submit invalid', data))}
          disabled={isSubmitting}
        >
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditMenuItem
