import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { Kiosk } from '../types'
import { v4 as uuidv4 } from 'uuid'

const kioskSchema = (t: TFunction<'translation', undefined>) => {
  // @ts-ignore
  const objectSchema: yup.ObjectSchema<Kiosk> = yup.object({
    id: yup
      .string()
      .transform(id => {
        if (!id) return uuidv4()
        return id
      })
      .default(uuidv4())
      .required(),
    restaurantId: yup.string().required(t('errors.required')),
    serialNumber: yup.string().required(t('errors.required')),
    name: yup.string().required(t('errors.required')),
    type: yup.string().oneOf(['kiosk']).default('kiosk').required(),
    model: yup.string().test('kiosk-model-autocomplete', t('errors.required'), (value, context) => {
      if (!context.parent?.printingMethod) {
        return false
      }
      return true
    }),
    printerPaperWidth: yup.string().oneOf(['58mm', '80mm']).default('80mm').required(),
    isCashSupported: yup.boolean().default(false).required(),
    isRequiredPhoneNumber: yup.boolean().default(false).required(),
    menuIds: yup.array().of(yup.string().defined().strict(true)).default([]).required(t('errors.required')),

    terminalSerialNumber: yup.string().default(undefined),
    terminalId: yup.string().default(undefined),
    externalId: yup.string(),
    couponScannerWebhook: yup.string().default(undefined),
    createdAt: yup.string().default(undefined),
    updatedAt: yup.string().default(undefined),
    appVersion: yup.string(),
    osVersion: yup.string(),
    buildNumber: yup.string(),
    vendorSerialNumber: yup.string(),
    lastActivityAt: yup.string().nullable(),
    shouldAutoPrintOrders: yup.boolean(),
    autoAcceptOrderInSeconds: yup.number(),
    printingMethod: yup.string().default('sunmi_v2'),
    useManualCut: yup.boolean().default(false),
    isBreakLine: yup.boolean().default(undefined),
    isAggressiveNotiEnabled: yup.boolean(),
    tableFormat: yup
      .object()
      .shape({
        name: yup.string().required(t('errors.required')),
        regex: yup.string().required(t('errors.required')),
        example: yup.string(),
      })
      .default(undefined),
    nameOrdering: yup.boolean(),
    forcedNameOrdering: yup.boolean().default(false),
    allowNoteInput: yup.boolean().default(false),
    acceptBarcodes: yup.boolean(),
    forcedBarcodes: yup.boolean().default(false),
    isMocking: yup.boolean().default(false),
  })

  return objectSchema
}

export default kioskSchema
