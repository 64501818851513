import moment from 'moment'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../../contexts/AuthContext'
import { updateMenuItemVisibility } from '../../../api/restaurantInfoApi'
import { useRecoilState } from 'recoil'
import selectedRestaurantMenuState from '../../../selectors/selectedRestaurantMenu'
import Localized from '../../../ui/Localized/Localized'
import usePriceFormat from '../../../hooks/usePriceFormat'

/**
 *
 */
function MenuItemStaff({ ...props }) {
  const { t, i18n } = useTranslation()
  const [showButtons, setShowButtons] = useState(false)
  const [selectedRestaurantMenu, setSelectedRestaurantMenu] = useRecoilState(selectedRestaurantMenuState)
  const { withCurrencySymbol } = usePriceFormat(true)

  const hidden = useMemo(() => {
    const hiddenTime = props?.data?.hiddenTime ? props.data.hiddenTime : 0
    const hiddenFrom = props?.data?.hiddenFrom
    if (props.index === 1) {
      //console.log("hidden what is this", hiddenTime, hiddenFrom);
    }
    if (hiddenTime === -1) {
      return true
    } else if (hiddenTime === 0) {
      return false
    } else {
      if (hiddenFrom) {
        if (moment.unix(hiddenFrom).add(hiddenTime, 'hour').isSameOrBefore(moment())) {
          return false
        }
      }
      return true
    }
  }, [props.data?.hiddenTime, props.data?.hiddenFrom])

  /**
   * Toggles hidden
   */
  const toggleHidden = (time: any) => {
    updateMenuItemVisibility({
      restaurantId: props.restaurantId,
      id: props.data.id,
      hiddenTime: time,
      hiddenFrom: moment().unix(),
    }).finally(() => {
      props.refreshMenuList(props.restaurantId)
    })
    setSelectedRestaurantMenu(
      selectedRestaurantMenu?.map((menuItem: any) => {
        if (menuItem.id === props.data.id) {
          return {
            ...menuItem,
            hiddenTime: time,
            hiddenFrom: moment().unix(),
          }
        }
        return menuItem
      })
    )
    setShowButtons(false)
  }

  return (
    <div>
      <div className={hidden ? 'menu-item-staff hidden' : 'menu-item-staff'}>
        <div className="item-row">
          <div className="item-info">
            <h5>
              <Localized target={props.data} translationsKey="title" />
            </h5>
            <p>
              <Localized target={props.data} translationsKey="description" />
            </p>
            <p className="price">{withCurrencySymbol(props.data.price)}</p>
          </div>
        </div>
        <div className="item-row-buttons-staff">
          <button
            className={`btn-flat dropdown-trigger`}
            style={{ border: '1px solid lightgray' }}
            onClick={() => setShowButtons(!showButtons)}
          >
            <span>{t('menuView.editMenuItem.dropdownHideTitle')}</span>
          </button>
          {showButtons ? (
            hidden ? (
              <button className="btn" onClick={() => toggleHidden(0)}>
                {t('menuView.editMenuItem.dropdownHideShow')}
              </button>
            ) : (
              <div className="buttons">
                <button className="btn" onClick={() => toggleHidden(1)}>
                  {t('menuView.editMenuItem.dropdownHideOneHour')}
                </button>

                <button className="btn" onClick={() => toggleHidden(3)}>
                  {t('menuView.editMenuItem.dropdownHideThreeHour')}
                </button>

                <button className="btn" onClick={() => toggleHidden(countDownToTomorrow())}>
                  {t('menuView.editMenuItem.dropdownHideTomorrow')}
                </button>

                <button className="btn" onClick={() => toggleHidden(-1)}>
                  {t('menuView.editMenuItem.dropdownHideForever')}
                </button>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default MenuItemStaff

/**
 * @returns Hours until tomorrow 2 AM
 */
function countDownToTomorrow() {
  var today = new Date()
  return 22 - today.getHours()
}
