import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  ClickAwayListener,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import './orderScreenEdit.scss'
import ColorPicker from '../../../../../../components/ColorPicker/ColorPicker'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useRecoilState } from 'recoil'
import { selectedRestaurantState } from '../../../../../../atoms/SelectedRestaurantAtom'
import { getAuth } from 'firebase/auth'
import { v4 as uuidv4 } from 'uuid'
import { uploadFileJPEG, uploadFilePNG } from '../../../../../../utils'
import useDebounce from '../../../../../../hooks/useDebounce'
import { saveRestaurantInfoData } from '../../../../../../api/restaurantInfoApi'
import parse from 'color-parse'
import { useRequestStatus } from '../../../../../../hooks/useRequestStatus'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

type CustomerMonitorTheme = {
  backgroundImage?: string | null
  backgroundColor?: string | null
  readyCardTextColor?: string | null
  readyTextColor?: string | null
  readyBackgroundColor?: string | null
  inProgressCardTextColor?: string | null
  inProgressTextColor?: string | null
  inProgressBackgroundColor?: string | null
  backgroundImageMode?: 'cover' | 'contain' | null
  uiVersion: '1' | '2'
}

const defaultTheme: CustomerMonitorTheme = {
  readyCardTextColor: '#fff',
  readyTextColor: '#000',
  readyBackgroundColor: '#19dc44',
  inProgressCardTextColor: '#000',
  inProgressTextColor: '#000',
  inProgressBackgroundColor: '#d9d9d9',
  backgroundColor: '#fff',
  backgroundImage: null,
  backgroundImageMode: 'cover',
  uiVersion: '2',
}

const getBASE_URL = (env: any) => {
  const BASE_URLS: any = {
    'pepi-internal-store-test': 'http://localhost:3000/order-status',
    'pepi-store-test': 'https://pepi-ordering-test.web.app/order-status',
    'pepi-cms': 'https://ordering.myntapp.io/order-status',
  }

  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000/order-status'
  } else {
    return BASE_URLS[env]
  }
}

function OrderScreenEdit() {
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const monitorSetting = selectedRestaurant.monitorSetting
  const [theme, setTheme] = useState<CustomerMonitorTheme>(combineObjects(defaultTheme, monitorSetting || {}))
  const [saving, setSaving] = useState(false)
  const [iframeParams, setIframeParams] = useState<string>('')
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [isGoToTooltipOpen, setGoToTooltipOpen] = useState(false)
  const { makeApiRequest } = useRequestStatus()

  const handleSave = async () => {
    setSaving(true)
    try {
      await makeApiRequest(
        saveRestaurantInfoData,
        [{ id: selectedRestaurant.id, monitorSetting: { ...theme, uiVersion: '2' } }],
        {
          pendingMessage: 'Saving monitor setting...',
        }
      )
    } finally {
      setSaving(false)
    }
  }

  const handleColorChange = useDebounce((variable: keyof CustomerMonitorTheme, color: any) => {
    setTheme({ ...theme, [variable]: `rgba(${color?.rgb?.r}, ${color?.rgb?.g}, ${color?.rgb?.b}, ${color?.rgb?.a})` })
  }, 500)

  const handleIframeLoad = () => {
    setIframeLoaded(true)
  }

  const handleBackgroundImageChange = async (e: any) => {
    const file = e?.target?.files?.[0]

    if (file) {
      const auth = await getAuth()
      const imagePath = `/users/${auth?.currentUser?.uid}/restaurants/${selectedRestaurant.id}/images/${uuidv4()}.jpeg`
      const image = await uploadFilePNG(file, imagePath)
      setTheme({ ...theme, backgroundImage: image })
    } else {
      setTheme({ ...theme, backgroundImage: null })
    }
  }

  const handleBackgroundImageModeChange = (e: any) => {
    console.log('e.target.value', e.target.value)
    setTheme({ ...theme, backgroundImageMode: e.target.value })
  }

  const handleReset = () => {
    setTheme(defaultTheme)
  }

  useEffect(() => {
    setIframeLoaded(false)

    const themeValues: any = {}
    Object.entries(theme).forEach(([key, value]) => {
      if (value) {
        themeValues[key] = value
      }
    })

    const newParams = new URLSearchParams({
      ...themeValues,
    })
    setIframeParams(newParams.toString())
    console.log('newParams', newParams.toString())
  }, [theme])

  console.log('monitor setting', selectedRestaurant?.monitorSetting)
  console.log('theme', theme)

  const orderStatusTestUrl = `${getBASE_URL(process.env.REACT_APP_FIREBASE_ENV || 'development')}/${
    selectedRestaurant?.id
  }?consumer=true&testing=true&${iframeParams}`
  const orderStatusUrlFixed = `${getBASE_URL(process.env.REACT_APP_FIREBASE_ENV || 'development')}/${
    selectedRestaurant?.id
  }?consumer=true&${iframeParams}`
  const orderStatusUrlDynamic = `${getBASE_URL(process.env.REACT_APP_FIREBASE_ENV || 'development')}/${
    selectedRestaurant?.id
  }?consumer=true`

  return (
    <Stack spacing={2} minHeight="100%" justifyContent="space-between">
      <Stack alignItems="center">
        <div className="order-status-preview-container">
          <iframe
            id="order-status-iframe"
            width="1920"
            height="1080"
            referrerPolicy="no-referrer"
            sandbox="allow-scripts allow-same-origin"
            src={orderStatusTestUrl}
            onLoad={handleIframeLoad}
          />
          <div className="iframe-placeholder"></div>
          {!iframeLoaded && (
            <div className="iframe-load">
              <Typography variant="h4" color="white">
                Loading preview...
              </Typography>
            </div>
          )}
        </div>
      </Stack>
      <Stack alignItems="center" justifyContent="center" direction="row" spacing={2}>
        <Button variant="outlined" onClick={handleReset}>
          Reset to default
        </Button>

        <Tooltip
          open={isGoToTooltipOpen}
          onClose={() => setGoToTooltipOpen(false)}
          arrow
          placement="top"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{ sx: { zIndex: 25 } }}
          title={
            <ClickAwayListener onClickAway={() => setGoToTooltipOpen(false)}>
              <div>
                <Stack spacing={1} direction="row" p={1}>
                  <Button variant="contained" target="_blank" href={orderStatusUrlFixed} sx={{ whiteSpace: 'nowrap' }}>
                    Fixed
                  </Button>
                  <Button
                    variant="contained"
                    target="_blank"
                    href={orderStatusUrlDynamic}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Dynamic (Recommended)
                  </Button>
                </Stack>
              </div>
            </ClickAwayListener>
          }
        >
          <Button variant="contained" onClick={() => setGoToTooltipOpen(true)}>
            Go to url
          </Button>
        </Tooltip>
      </Stack>
      <Stack pb={5} pt={5} spacing={4}>
        <Stack spacing={1}>
          <Typography variant="h5">Ready Orders</Typography>
          <Grid container>
            <Grid item xs={4}>
              <Stack justifyContent="center">
                <Typography variant="body1">Ready orders header</Typography>
                <ColorPicker
                  color={parseColor(theme.readyTextColor)}
                  onChange={(color: string) => handleColorChange('readyTextColor', color)}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack justifyContent="center">
                <Typography variant="body1">Ready orders text</Typography>
                <ColorPicker
                  color={parseColor(theme.readyCardTextColor)}
                  onChange={(color: string) => handleColorChange('readyCardTextColor', color)}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack justifyContent="center">
                <Typography variant="body1">Ready orders background color</Typography>
                <ColorPicker
                  color={parseColor(theme.readyBackgroundColor)}
                  onChange={(color: string) => handleColorChange('readyBackgroundColor', color)}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h5">In progress Orders</Typography>
          <Grid container>
            <Grid item xs={4}>
              <Stack justifyContent="center">
                <Typography variant="body1">In progress orders header</Typography>
                <ColorPicker
                  color={parseColor(theme.inProgressTextColor)}
                  onChange={(color: string) => handleColorChange('inProgressTextColor', color)}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack justifyContent="center">
                <Typography variant="body1">In progress orders text</Typography>
                <ColorPicker
                  color={parseColor(theme.inProgressCardTextColor)}
                  onChange={(color: string) => handleColorChange('inProgressCardTextColor', color)}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack justifyContent="center">
                <Typography variant="body1">In progress orders background color</Typography>
                <ColorPicker
                  color={parseColor(theme.inProgressBackgroundColor)}
                  onChange={(color: string) => handleColorChange('inProgressBackgroundColor', color)}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h5">Background</Typography>
          <Stack spacing={2}>
            <Stack justifyContent="center">
              <Typography variant="body1">Background color</Typography>
              <ColorPicker
                color={parseColor(theme.backgroundColor)}
                onChange={(color: string) => handleColorChange('backgroundColor', color)}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h5">Background image</Typography>
          <Stack justifyContent="center">
            <Typography variant="subtitle1"></Typography>
            <Stack spacing={1}>
              <Stack spacing={1}>
                <Box
                  component="img"
                  src={theme.backgroundImage || ''}
                  sx={{ width: '100px', height: '80px', objectFit: 'contain', backgroundColor: '#f0f0f0' }}
                />
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    disabled={Boolean(theme.backgroundImage)}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Background image
                    <VisuallyHiddenInput type="file" onChange={handleBackgroundImageChange} />
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleBackgroundImageChange}
                    disabled={!Boolean(theme.backgroundImage)}
                  >
                    Remove Background image
                  </Button>
                </Stack>
              </Stack>
              <FormControl>
                <Typography variant="subtitle1">Image mode</Typography>
                <Stack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={theme?.backgroundImageMode === 'cover'}
                        onChange={handleBackgroundImageModeChange}
                        value={'cover'}
                      />
                    }
                    label="Cover"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={theme?.backgroundImageMode === 'contain'}
                        onChange={handleBackgroundImageModeChange}
                        value={'contain'}
                      />
                    }
                    label="Contain"
                  />
                </Stack>
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Button variant="contained" size="large" onClick={handleSave} disabled={saving}>
        Save
      </Button>
    </Stack>
  )
}

export default OrderScreenEdit

// More priority to second object's fields
function combineObjects(obj1: any, obj2: any) {
  const result: any = {}

  Object.entries(obj1).forEach(([key, value]) => {
    if (obj2[key]) {
      result[key] = obj2[key]
    } else {
      result[key] = value
    }
  })

  return result
}

function parseColor(color: any) {
  console.log('color', color)
  const parsed = parse(color)
  console.log('parsed', parsed)

  if (parsed.space === 'rgb') {
    return {
      r: parsed.values[0],
      g: parsed.values[1],
      b: parsed.values[2],
      a: parsed.alpha,
    }
  }

  return color
}
