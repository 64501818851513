import { useTranslation } from 'react-i18next'
import Spinner from '../../ui/Spinner/Spinner'

function Loading() {
  const { t } = useTranslation()

  return (
    <div className="row">
      <div className="col s12">
        <div className="loading-wrapper">
          <div className="loading-row">
            <Spinner />
          </div>
          <div className="loading-row">
            <h5>{t('general.Loading')}</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading
