import React, { useState, useEffect } from 'react'
import OrderScreenDefaultView from '../../../images/OrderScreenDefault.png'
import Toggelable from '../../../components/Toggleable/Toggleable'
import OrderScreenPreview from './OrderScreenPreview'
import { SketchPicker } from 'react-color'
import ColorPicker from '../../../components/ColorPicker/ColorPicker'
import { saveRestaurantInfoData } from '../../../api/restaurantInfoApi'

export default function OrderScreenEdit(props) {
  const monitorSetting = props.restaurant.monitorSetting
  const [theme, setTheme] = useState(monitorSetting?.theme || 'default')
  const [inProgressBackgroundColor, setInProgressBackgroundColor] = useState(
    monitorSetting?.inProgressBackgroundColor || '#000'
  )
  const [inprogressTextColor, setInProgresTextColor] = useState(monitorSetting?.inProgressTextColor || '#FFF')
  const [readyBgColor, setReadyBgColor] = useState(monitorSetting?.readyBackgroundColor || '#d1ffca')
  const [readyTextColor, setReadyTextColor] = useState(monitorSetting?.readyTextColor || '#000')

  console.log('props: ', props.restaurant.id)

  const [saving, setSaving] = useState(false)

  const saveKioskSettings = async () => {
    setSaving(true)
    try {
      await saveRestaurantInfoData({
        id: props.restaurant.id,
        monitorSetting: {
          theme,
          inProgressBackgroundColor,
          inProgressTextColor: inprogressTextColor,
          readyBackgroundColor: readyBgColor,
          readyTextColor: readyTextColor,
        },
      })
    } catch (error) {
      alert(error.message)
    } finally {
      // refresh restaurant info
      setSaving(false)
    }
  }

  return (
    <div className="card">
      <div className="card-content">
        <div className="row row-editing">
          <Toggelable
            title={'Order Screen Settings'}
            description={'In this section you can control the order screen in your venue.'}
          >
            <h5>Order Screen styling</h5>
            <div className="row row-editing">
              <div className="col">
                <div
                  className={`selectable-image order-screen ${theme === 'default' ? 'selected' : ''}`}
                  onClick={() => setTheme('default')}
                >
                  <img src={OrderScreenDefaultView} />
                  <div className="title">Default</div>
                </div>
              </div>
              <div className="col">
                <div
                  className={`selectable-image order-screen ${theme === 'customized' ? 'selected' : ''}`}
                  onClick={() => setTheme('customized')}
                >
                  <OrderScreenPreview
                    inProgressBackgroundColor={inProgressBackgroundColor}
                    inProgressTextColor={inprogressTextColor}
                    readyBackgroundColor={readyBgColor}
                    readyTextColor={readyTextColor}
                  />
                  <div className="title">Customized</div>
                </div>
              </div>
              {theme === 'customized' && (
                <div className="col w-50">
                  <div className="col">
                    <div className="row">
                      <b>In Progress Backgorund color</b>
                      <ColorPicker color={inProgressBackgroundColor} onChange={e => setInProgressBackgroundColor(e)} />
                    </div>

                    <div className="row">
                      <b>In Progress Text color</b>
                      <ColorPicker color={inprogressTextColor} onChange={e => setInProgresTextColor(e)} />
                    </div>

                    <a
                      className="btn-small"
                      href={'https://ordering.myntapp.io/order-status/' + props.restaurant.id + '?consumer=true'}
                      target="_blank"
                    >
                      Preview
                    </a>
                  </div>
                  <div className="col">
                    <div className="row">
                      <b>Ready Background color</b>
                      <ColorPicker color={readyBgColor} onChange={e => setReadyBgColor(e)} />
                    </div>
                    <div className="row">
                      <b>Ready Text Color</b>
                      <ColorPicker color={readyTextColor} onChange={e => setReadyTextColor(e)} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <button className={`btn save-btn ${saving ? 'disabled' : ''}`} onClick={saveKioskSettings}>
              Save
            </button>
          </Toggelable>
        </div>
      </div>
    </div>
  )
}
