import { v4 as uuidv4 } from 'uuid'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'

export const convertToJPEG = async file => {
  const isBlob = typeof file === 'string' ? file.indexOf('blob:') >= 0 : false
  const src = isBlob ? file : URL.createObjectURL(file)
  const name = isBlob ? 'File' : file.name
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = function () {
      try {
        URL.revokeObjectURL(this.src) // free up memory
        var c = document.createElement('canvas'), // create a temp. canvas
          ctx = c.getContext('2d')
        const maxDim = {
          width: 1024,
          height: 1024,
        }
        const baseDim = this.width > this.height ? 'width' : 'height'
        const originRatio = this.width / this.height
        c[baseDim] = this[baseDim] > maxDim[baseDim] ? maxDim[baseDim] : this[baseDim]
        c[baseDim === 'width' ? 'height' : 'width'] =
          baseDim === 'width' ? c[baseDim] / originRatio : c[baseDim] * originRatio
        ctx.clearRect(0, 0, c.width, c.height)
        ctx.drawImage(this, 0, 0, this.width, this.height, 0, 0, c.width, c.height)

        // convert to File object, NOTE: we're using binary mime-type for the final Blob/File
        c.toBlob(
          function (blob) {
            var file = new File([blob], `${name}.jpg`, {
              type: 'application/octet-stream',
            })
            resolve(file)
          },
          'image/jpeg',
          0.75
        )
      } catch (error) {
        reject(error)
      }
    }
  })
}

export const uploadFileJPEG = async (f, filePath) => {
  const storage = getStorage()
  const auth = await getAuth()
  const uid = auth.currentUser.uid

  let imageUrl = f
  console.log('f', typeof f, f instanceof Blob, f)
  if (typeof f === 'string' && f.indexOf('blob:') < 0) {
    console.log('not uploading file')
  } else {
    console.log('uploading file')
    const imageInJPEG = await convertToJPEG(f)
    const storageRef = ref(storage, `/users/${uid}/${filePath}`)
    const snapshot = await uploadBytes(storageRef, imageInJPEG, {
      contentType: 'image/jpeg',
      cacheControl: 'public,max-age=2592000',
    })
    imageUrl = await getDownloadURL(snapshot.ref)
  }
  return imageUrl
}

export const convertToPNG = async file => {
  const isBlob = typeof file === 'string' ? file.indexOf('blob:') >= 0 : false
  const src = isBlob ? file : URL.createObjectURL(file)
  const name = isBlob ? 'File' : file.name
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = function () {
      try {
        URL.revokeObjectURL(this.src) // free up memory
        var c = document.createElement('canvas'), // create a temp. canvas
          ctx = c.getContext('2d')
        const maxDim = {
          width: 1024,
          height: 1024,
        }
        const baseDim = this.width > this.height ? 'width' : 'height'
        const originRatio = this.width / this.height
        c[baseDim] = this[baseDim] > maxDim[baseDim] ? maxDim[baseDim] : this[baseDim]
        c[baseDim === 'width' ? 'height' : 'width'] =
          baseDim === 'width' ? c[baseDim] / originRatio : c[baseDim] * originRatio
        ctx.clearRect(0, 0, c.width, c.height)
        ctx.drawImage(this, 0, 0, this.width, this.height, 0, 0, c.width, c.height)

        // convert to File object, NOTE: we're using binary mime-type for the final Blob/File
        c.toBlob(
          function (blob) {
            var file = new File([blob], `${name}.png`, {
              type: 'application/octet-stream',
            })
            resolve(file)
          },
          'image/png',
          0.75
        )
      } catch (error) {
        reject(error)
      }
    }
  })
}

export const uploadFilePNG = async (f, filePath) => {
  const storage = getStorage()
  const auth = await getAuth()
  const uid = auth.currentUser.uid

  let imageUrl = f
  console.log('f', typeof f, f instanceof Blob, f)
  if (typeof f === 'string' && f.indexOf('blob:') < 0) {
    console.log('not uploading file')
  } else {
    console.log('uploading file')
    const imageInPNG = await convertToPNG(f)
    const storageRef = ref(storage, `/users/${uid}/${filePath}`)
    const snapshot = await uploadBytes(storageRef, imageInPNG, {
      contentType: 'image/png',
      cacheControl: 'public,max-age=2592000',
    })
    imageUrl = await getDownloadURL(snapshot.ref)
  }
  return imageUrl
}

export const uploadFile = async (f, filePath, fileType = undefined) => {
  const storage = getStorage()
  const auth = await getAuth()
  const uid = auth.currentUser.uid

  let imageUrl = f
  console.log('f', typeof f, f instanceof Blob, f)
  if (typeof f === 'string' && f.indexOf('blob:') < 0) {
    console.log('not uploading file')
  } else {
    console.log('uploading file')
    let filePayload = f
    let contentType = fileType
    if (fileType === 'image/png') {
      filePayload = await convertToPNG(f)
    } else if (fileType === 'image/jpg' || fileType === 'image/jpeg') {
      filePayload = await convertToJPEG(f)
    } else if (contentType === undefined && f.name) {
      if (f.name.indexOf('.png') >= 0) {
        filePayload = await convertToPNG(f)
        contentType = 'image/png'
      } else if (f.name.indexOf('.jpg') >= 0) {
        filePayload = await convertToJPEG(f)
        contentType = 'image/jpeg'
      } else if (f.name.indexOf('.jpeg') >= 0) {
        filePayload = await convertToJPEG(f)
        contentType = 'image/jpeg'
      } else if (f.name.indexOf('.pdf') >= 0) {
        filePayload = f
        contentType = 'application/pdf'
      } else if (f.name.indexOf('.mp4') >= 0) {
        filePayload = f
        contentType = 'video/mp4'
      }
    }
    const storageRef = ref(storage, `/users/${uid}/${filePath}`)
    const snapshot = await uploadBytes(storageRef, filePayload, {
      contentType: fileType,
      cacheControl: 'public,max-age=2592000',
    })
    imageUrl = await getDownloadURL(snapshot.ref)
  }
  return imageUrl
}
