import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { convertToJPEG, uploadFileJPEG } from '../../../utils'
import Toggelable from '../../../components/Toggleable/Toggleable'
import ReceiptPreview from './ReceiptPreview'
import { saveRestaurantInfoData } from '../../../api/restaurantInfoApi'
import { AVAILABLE_LANGUAGES } from '../../../constants'

const BrandingIdentityEdit = props => {
  console.log('Props rest, ', props.restaurant)
  const [name, setName] = useState(props?.restaurant?.receiptSetting?.locationName || props.restaurant.name)
  const [sellerName, setSellerName] = useState(props?.restaurant?.receiptSetting?.sellerName)
  const [sellerLocation, setSellerLocation] = useState(props?.restaurant?.receiptSetting?.setSellerName)
  const [receiptMessage, setReceiptMessage] = useState(props.restaurant.receiptMessage)
  const [phone, setPhone] = useState(props.restaurant?.receiptSetting?.phone || props.restaurant.phone)
  const [address, setAddress] = useState(props.restaurant?.receiptSetting?.address || props.restaurant.address)
  const [ytunnus, setYtunnus] = useState(props.restaurant?.receiptSetting?.businessId)
  const [city, setCity] = useState(props.restaurant?.receiptSetting?.city || props.restaurant.city)
  const [zipcode, setZipcode] = useState(props.restaurant?.receiptSetting?.zipCode || props.restaurant.zipCode)
  const [logo, setLogo] = useState(props.restaurant?.receiptSetting?.logo)
  const [saving, setSaving] = useState(false)

  const saveIdentity = async () => {
    setSaving(true)
    try {
      const filePath = `/restaurants/${props.restaurant.id}/images/${uuidv4()}.jpeg`
      const imageUrl = logo ? await uploadFileJPEG(logo, filePath) : null
      await saveRestaurantInfoData({
        id: props.restaurant.id,
        receiptSetting: {
          address,
          locationName: name,
          sellerName,
          sellerLocation,
          phone,
          businessId: ytunnus,
          city,
          zipCode: zipcode,
          logo: imageUrl ? imageUrl : props.restaurant?.receiptSetting?.logo,
        },
      })
    } catch (error) {
      alert(error.message)
    } finally {
      // refresh restaurant info
      setSaving(false)
    }
  }

  const handleLogoChange = event => {
    let input = URL.createObjectURL(event.target.files[0])
    setLogo(input)
  }

  return (
    <div className="card">
      <div className="card-content">
        <div className="row row-editing">
          <Toggelable
            title={'Location & Receipt details'}
            description={
              'In this section you can control the identity of your venue and how its reflected on your receipts.'
            }
          >
            <div className="row m-0">
              <div className="col w-50">
                <h5>Location Name</h5>
                <p className="info-text">Appears at the end of your reciept</p>
                <input type="text" value={name} onChange={event => setName(event.target.value)} />

                <h5>Seller Name</h5>
                <input type="text" value={sellerName} onChange={event => setSellerName(event.target.value)} />
                <h5>Seller Location</h5>
                <input type="text" value={sellerLocation} onChange={event => setSellerLocation(event.target.value)} />

                <h5>Phone Number</h5>
                <input type="text" value={phone} onChange={event => setPhone(event.target.value)} />

                <h5>Address</h5>
                <input type="text" value={address} onChange={event => setAddress(event.target.value)} />

                <h5>Ytunnus</h5>
                <input type="text" value={ytunnus} onChange={event => setYtunnus(event.target.value)} />

                <h5>City</h5>
                <input type="text" value={city} onChange={event => setCity(event.target.value)} />

                <h5>Zipcode</h5>
                <input type="text" value={zipcode} onChange={event => setZipcode(event.target.value)} />

                <h5>Reciept Logo</h5>
                <p>Logo to be printed at end of reciept</p>

                <div className="file-field input-field">
                  <div
                    className="btn tooltipped"
                    data-position="top"
                    data-tooltip="60x60 Black and White image for best result"
                  >
                    <span>File</span>
                    <input type="file" accept="image/*" onChange={handleLogoChange} />
                  </div>
                  <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" />
                  </div>
                </div>

                <div className="m-t-30">
                  <button className={`btn ${saving ? 'saving' : ''}`} onClick={saveIdentity}>
                    Save Identity
                  </button>
                </div>
              </div>

              <div className="col w-50">
                <ReceiptPreview
                  RestaurantName={name}
                  SellerName={sellerName}
                  SellerLocation={sellerLocation}
                  ReceiptLogo={logo}
                  Address={address}
                  Ytunnus={ytunnus}
                  Zipcode={zipcode}
                  City={city}
                  CustomerMessage={receiptMessage}
                />
              </div>
            </div>
          </Toggelable>
        </div>
      </div>
    </div>
  )
}

export default BrandingIdentityEdit
