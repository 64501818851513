import React, { useEffect, useState } from 'react'
import DeviceKioskTableRow from './tableRows/DeviceKioskTableRow'
import { Kiosk, CustomerMonitor, KitchenMonitor, Printer, QRCode } from '../DeviceConstants'
import DevicePrinterTableRow from './tableRows/DevicePrinterTableRow'
import { deleteDevice, updateDevice } from '../../../api/deviceApi'
import DeviceMonitorTableRow from './tableRows/DeviceMonitorTableRow'
import { useRequestStatus } from '../../../hooks/useRequestStatus'
import { useRecoilState } from 'recoil'
import selectedRestaurantDevicesState from '../../../selectors/selectedRestaurantDevices'
import DeviceQRCodeTableRow from './tableRows/DeviceQRCodeTableRow'

const refresh = () => null

function DeviceTable({ deviceFilter }: any) {
  const [selectedRestaurantDevices, setSelectedRestaurantDevices] = useRecoilState(selectedRestaurantDevicesState)
  const [deviceData, setDeviceData] = useState([])
  const { makeApiRequest } = useRequestStatus()

  const handleDelete = async (id: any) => {
    const { isSuccess } = await makeApiRequest(deleteDevice, [id])
    if (isSuccess) {
      setSelectedRestaurantDevices((prev: any) => {
        console.log('prev', prev, id)
        return prev.filter((device: any) => device.id !== id)
      })
    }
  }

  const handleDeviceUpdate = async (id: any, data: any) => {
    const { isSuccess } = await makeApiRequest(updateDevice, [id, data])
    if (isSuccess) {
      setSelectedRestaurantDevices((prev: any) => {
        return prev.map((device: any) => {
          if (device.id === id) {
            return data
          }
          return device
        })
      })
    }
  }

  // Filter the table
  useEffect(() => {
    if (deviceFilter) {
      const newData = selectedRestaurantDevices.filter((device: any) => device.type == deviceFilter)
      setDeviceData(newData)
    } else {
      setDeviceData(selectedRestaurantDevices)
    }
  }, [deviceFilter, selectedRestaurantDevices])

  return (
    <tbody>
      {deviceData.map((device: any) => {
        switch (device.type) {
          case Kiosk:
            return (
              <DeviceKioskTableRow
                key={device.id}
                device={device}
                delete={handleDelete}
                refresh={refresh}
                onUpdate={handleDeviceUpdate}
              />
            )
          case Printer:
            return (
              <DevicePrinterTableRow
                key={device.id}
                device={device}
                delete={handleDelete}
                refresh={refresh}
                onUpdate={handleDeviceUpdate}
              />
            )
          case CustomerMonitor:
          case KitchenMonitor:
            return (
              <DeviceMonitorTableRow
                key={device.id}
                device={device}
                delete={handleDelete}
                refresh={refresh}
                onUpdate={handleDeviceUpdate}
              />
            )
          case QRCode:
            return (
              <DeviceQRCodeTableRow
                key={device.id}
                device={device}
                delete={handleDelete}
                onUpdate={handleDeviceUpdate}
              />
            )

          default:
            console.log(device.type)
            break
        }
      })}
    </tbody>
  )
}

export default DeviceTable
