import { Printer } from 'prettier'
import { FormProvider, useForm } from 'react-hook-form'
import useYupValidationResolver from '../../../../hooks/useYupValidationResolver'
import qrCodeSchema from '../../../../schemas/qrCodeSchema'
import { QRCode } from '../../../../types'
import { timeAgo } from '../../../../utils/timeAgo'
import { SubMenuIdToName } from '../../SubMenuIdToName'
import { useState } from 'react'
import { Stack, Button } from '@mui/material'
import DeleteConfirmButton from '../../../../ui/DeleteConfirmButton/DeleteConfirmButton'
import QRCodeForm from '../../../../components/forms/QRCodeForm'
import { ShowCode } from '../../QRCodeShowCode'

type DeviceQRCodeTableRowProps = {
  device: QRCode
  onUpdate: (id: string, data: any) => void
  delete?: any
}

function DeviceQRCodeTableRow({ device, onUpdate, ...rest }: DeviceQRCodeTableRowProps) {
  const [showForm, setShowForm] = useState(false)
  const [showCode, setShowCode] = useState(false)

  const resolver = useYupValidationResolver(qrCodeSchema)
  const methods = useForm<QRCode>({
    resolver,
    defaultValues: device,
  })

  const onSubmit = (data: any) => {
    onUpdate(device.id, data)
  }

  const handleShowCode = () => {
    setShowCode(!showCode)
    setShowForm(false)
  }

  const handleCancelClick = () => {
    setShowForm(false)
  }

  return (
    <>
      <tr>
        <td>{device.name}</td>
        <td>{device.type}</td>
        <td>{timeAgo(device.lastActivityAt || device.updatedAt)}</td>
        <td>
          {Array.isArray(device?.menuIds) ? device?.menuIds?.map((e: any) => SubMenuIdToName(e)) : device?.menuIds}
        </td>

        <td className="btn-wrapper">
          <button className="btn" onClick={() => handleShowCode()}>
            See Code
          </button>
          <button className="btn" onClick={() => setShowForm(!showForm)}>
            Edit
          </button>
        </td>
      </tr>
      {showCode && (
        <tr className="edit-row row">
          <td colSpan={6}>
            <ShowCode serialNumber={device.serialNumber} menuIds={device.menuIds} restaurantId={device.restaurantId} />
          </td>
        </tr>
      )}
      {showForm && (
        <tr>
          <td colSpan={6}>
            <Stack p={2} spacing={3}>
              <FormProvider {...methods}>
                <QRCodeForm />
              </FormProvider>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={methods.handleSubmit(onSubmit, e => console.log('Submit failed: ', e))}
                >
                  Save
                </Button>
                <Button variant="contained" color="info" onClick={handleCancelClick}>
                  Cancel
                </Button>
                <DeleteConfirmButton targetText="this QR" onClick={() => rest.delete(device.id)}>
                  Delete QR
                </DeleteConfirmButton>
              </Stack>
            </Stack>
          </td>
        </tr>
      )}
    </>
  )
}

export default DeviceQRCodeTableRow
