import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import KioskDefaultImg from '../../../images/KioskDefault.jpg'
import KioskPreview from './KioskPreview'
import M from 'materialize-css'
import Toggelable from '../../../components/Toggleable/Toggleable'
import { uploadFilePNG } from '../../../utils'
import { saveRestaurantInfoData } from '../../../api/restaurantInfoApi'

export default function KioskEditCard(props) {
  const setting = props.restaurant.kioskSetting
  const [logo, setLogo] = useState(setting?.logo || null)
  const [background, setBackground] = useState(setting?.background || null)
  const [splash, setSplash] = useState(setting?.splash || null)
  const [theme, setTheme] = useState(setting?.theme || 'default')
  const [saving, setSaving] = useState(false)

  const saveKioskSettings = async () => {
    setSaving(true)
    const storage = getStorage()
    const auth = await getAuth()
    const uid = auth.currentUser.uid
    try {
      const _uploadFile = async f => {
        const filePath = `/restaurants/${props.restaurant.id}/images/${uuidv4()}.png`
        const imageUrl = await uploadFilePNG(f, filePath)
        return imageUrl
      }

      console.log('logo', logo)
      let logoUrl = ''
      let splashUrl = ''
      let backgroundUrl = ''

      if (logo && logo != 'NONE') {
        logoUrl = await _uploadFile(logo)
      }

      if (splash && splash != 'NONE') {
        splashUrl = await _uploadFile(splash)
      }

      if (background) {
        backgroundUrl = await _uploadFile(background)
      }

      await saveRestaurantInfoData({
        id: props.restaurant.id,
        kioskSetting: {
          theme,
          logo: logo == 'NONE' ? logo : logoUrl,
          background: backgroundUrl,
          splash: splash == 'NONE' ? logo : splashUrl,
        },
      })
    } catch (error) {
      console.log('Error: ', error)
      alert(error.message)
    } finally {
      // refresh restaurant info
      setSaving(false)
    }
  }

  useEffect(() => {
    let tooltips = document.querySelectorAll('.tooltipped')
    M.Tooltip.init(tooltips, {})
  })

  const handleLogoChange = event => {
    let input = URL.createObjectURL(event.target.files[0])
    setLogo(input)
  }

  const handleBackgroundChange = event => {
    let input = URL.createObjectURL(event.target.files[0])
    setBackground(input)
  }

  const handleSplashChange = event => {
    let input = URL.createObjectURL(event.target.files[0])
    console.log(input)
    setSplash(input)
  }

  const handleSetThemeDefault = () => {
    setTheme('default')
    setSplash(null)
    setLogo(null)
    setBackground(null)
  }

  return (
    <div className="card">
      <div className="card-content">
        <div className="row row-editing">
          <Toggelable
            title={'Kiosk Settings'}
            description={'In this section you can control the look of your kiosk home view in your venue.'}
          >
            <h5>Kiosk theme</h5>
            <div className="row row-editing">
              <div className="col">
                <div
                  className={`selectable-image ${theme === 'default' ? 'selected' : ''}`}
                  onClick={() => handleSetThemeDefault()}
                >
                  <img src={KioskDefaultImg} />
                  <div className="title">Default</div>
                </div>
              </div>
              <div className="col">
                <div
                  className={`selectable-image ${theme === 'customized' ? 'selected' : ''}`}
                  onClick={() => setTheme('customized')}
                >
                  <KioskPreview logo={logo} backgroundImage={background} splashImage={splash} />
                  <div className="title">Customized</div>
                </div>
              </div>
              {theme === 'customized' && (
                <div className="col w-50 kiosk-settings-wrapper">
                  <div className="option-row">
                    <div className="option">
                      <b>
                        Kiosk logo <span>(Transperent PNG, 500x200)</span>
                      </b>

                      <div className="file-field input-field">
                        <div className="btn">
                          <span>File</span>
                          <input type="file" accept="image/*" onChange={handleLogoChange} />
                        </div>
                        <div className="file-path-wrapper">
                          <input className="file-path validate" type="text" />
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn-small tooltipped"
                      data-position="top"
                      onClick={() => setLogo('NONE')}
                      data-tooltip="No Logo"
                    >
                      <i className="material-icons">visibility_off</i>
                    </button>
                  </div>

                  <div className="option-row">
                    <div className="option">
                      <b>
                        Kiosk Splash <span>(Transperent PNG, 780x584) </span>
                      </b>
                      <div className="file-field input-field">
                        <div className="btn">
                          <span>File</span>
                          <input type="file" accept="image/*" onChange={handleSplashChange} />
                        </div>
                        <div className="file-path-wrapper">
                          <input className="file-path validate" type="text" />
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn-small tooltipped"
                      data-position="top"
                      onClick={() => setSplash('NONE')}
                      data-tooltip="No Splash"
                    >
                      <i className="material-icons">visibility_off</i>
                    </button>
                  </div>

                  <div className="option-row">
                    <div className="option">
                      <b>
                        Kiosk Background <span>(JPG, 1080x1920)</span>
                      </b>
                      <div className="file-field input-field">
                        <div
                          className="btn tooltipped"
                          data-position="top"
                          data-tooltip="Corners of your background may get cut off on the smaller Kiosk"
                        >
                          <span>File</span>
                          <input type="file" accept="image/*" onChange={handleBackgroundChange} />
                        </div>
                        <div className="file-path-wrapper">
                          <input className="file-path validate" type="text" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="info-box">
                    Please keep in mind that the preview to the left might not be 100% accurate. Please verify your
                    settings on your actual kiosk.
                  </div>
                </div>
              )}
            </div>
            <button className={`btn save-btn ${saving ? 'disabled' : ''}`} onClick={saveKioskSettings}>
              Save Kiosk settings
            </button>
          </Toggelable>
        </div>
      </div>
    </div>
  )
}
