import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import KioskDefaultImg from '../../../../images/KioskDefault.jpg'
import WhiteMode from '../../../../images/whiteMode.png'
import DarkMode from '../../../../images/darkmode.jpg'
import CustomTheme from '../../../../images/KioskCustomTheme.png'
import { useAuthContext } from '../../../../contexts/AuthContext'
import KioskPreview from './KioskPreview'
import M from 'materialize-css'
import Toggelable from '../../../../components/Toggleable/Toggleable'
import { uploadFilePNG } from '../../../../utils'
import { saveRestaurantInfoData } from '../../../../api/restaurantInfoApi'
import { Stack, Button } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../../atoms/SelectedRestaurantAtom'
import { set } from 'react-hook-form'

export default function KioskEditCard() {
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const setting = selectedRestaurant.kioskSetting
  const [logo, setLogo] = useState(setting?.logo || null)
  const [background, setBackground] = useState(setting?.background || null)
  const [splash, setSplash] = useState(setting?.splash || null)
  const [theme, setTheme] = useState(setting?.theme || 'default')
  const [colorTheme, setColorTheme] = useState(setting?.colorTheme || 'default')
  const [showCouponInput, setShowCouponInput] = useState(setting?.showCouponInput || false)
  const [saving, setSaving] = useState(false)
  const { isAdmin } = useAuthContext()

  const saveKioskSettings = async () => {
    setSaving(true)
    const storage = getStorage()
    const auth = await getAuth()
    const uid = auth.currentUser.uid
    try {
      const _uploadFile = async f => {
        const filePath = `/restaurants/${selectedRestaurant.id}/images/${uuidv4()}.png`
        const imageUrl = await uploadFilePNG(f, filePath)
        return imageUrl
      }

      console.log('logo', logo)
      let logoUrl = ''
      let splashUrl = ''
      let backgroundUrl = ''

      if (logo && logo != 'NONE') {
        logoUrl = await _uploadFile(logo)
      }

      if (splash && splash != 'NONE') {
        splashUrl = await _uploadFile(splash)
      }

      if (background) {
        backgroundUrl = await _uploadFile(background)
      }

      await saveRestaurantInfoData({
        id: selectedRestaurant.id,
        kioskSetting: {
          theme,
          colorTheme,
          logo: logo == 'NONE' ? logo : logoUrl,
          background: backgroundUrl,
          splash: splash == 'NONE' ? logo : splashUrl,
          showCouponInput,
        },
      })
    } catch (error) {
      console.log('Error: ', error)
      alert(error.message)
    } finally {
      // refresh restaurant info
      setSaving(false)
    }
  }

  const handleSave = () => {
    console.log('save')
    saveKioskSettings()
  }

  useEffect(() => {
    let tooltips = document.querySelectorAll('.tooltipped')
    M.Tooltip.init(tooltips, {})
  })

  const handleLogoChange = event => {
    let input = URL.createObjectURL(event.target.files[0])
    setLogo(input)
  }

  const handleBackgroundChange = event => {
    let input = URL.createObjectURL(event.target.files[0])
    setBackground(input)
  }

  const handleSplashChange = event => {
    let input = URL.createObjectURL(event.target.files[0])
    console.log(input)
    setSplash(input)
  }

  useEffect(() => {
    setLogo(setting?.logo || null)
    setBackground(setting?.background || null)
    setSplash(setting?.splash || null)
    setTheme(setting?.theme || 'default')
  }, [selectedRestaurant])

  const handleSetThemeDefault = () => {
    setTheme('default')
    setSplash(null)
    setLogo(null)
    setBackground(null)
  }

  return (
    <Stack minHeight="100%" justifyContent="space-between">
      <div className="branding-container">
        <div className="row row-editing">
          <h5>Welcome Screen</h5>
          <div className="row row-editing">
            <div className="col">
              <div
                className={`selectable-image ${theme === 'default' ? 'selected' : ''}`}
                onClick={() => handleSetThemeDefault()}
              >
                <img src={KioskDefaultImg} />
                <div className="title">Default</div>
              </div>
            </div>
            <div className="col">
              <div
                className={`selectable-image ${theme === 'customized' ? 'selected' : ''}`}
                onClick={() => setTheme('customized')}
              >
                <KioskPreview logo={logo} backgroundImage={background} splashImage={splash} />
                <div className="title">Customized</div>
              </div>
            </div>
            {theme === 'customized' && (
              <div className="col kiosk-settings-wrapper">
                <div className="option-row">
                  <div className="option">
                    <b>
                      Kiosk logo <span>(Transperent PNG, 500x200)</span>
                    </b>

                    <div className="file-field input-field">
                      <div className="btn">
                        <span>File</span>
                        <input type="file" accept="image/*" onChange={handleLogoChange} />
                      </div>
                      <div className="file-path-wrapper">
                        <input className="file-path validate" type="text" />
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn-small tooltipped"
                    data-position="top"
                    onClick={() => setLogo('NONE')}
                    data-tooltip="No Logo"
                  >
                    <i className="material-icons">visibility_off</i>
                  </button>
                </div>

                <div className="option-row">
                  <div className="option">
                    <b>
                      Kiosk Splash <span>(Transperent PNG, 780x584) </span>
                    </b>
                    <div className="file-field input-field">
                      <div className="btn">
                        <span>File</span>
                        <input type="file" accept="image/*" onChange={handleSplashChange} />
                      </div>
                      <div className="file-path-wrapper">
                        <input className="file-path validate" type="text" />
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn-small tooltipped"
                    data-position="top"
                    onClick={() => setSplash('NONE')}
                    data-tooltip="No Splash"
                  >
                    <i className="material-icons">visibility_off</i>
                  </button>
                </div>

                <div className="option-row">
                  <div className="option">
                    <b>
                      Kiosk Background <span>(JPG, 1080x1920)</span>
                    </b>
                    <div className="file-field input-field">
                      <div
                        className="btn tooltipped"
                        data-position="top"
                        data-tooltip="Corners of your background may get cut off on the smaller Kiosk"
                      >
                        <span>File</span>
                        <input type="file" accept="image/*" onChange={handleBackgroundChange} />
                      </div>
                      <div className="file-path-wrapper">
                        <input className="file-path validate" type="text" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="info-box">
                  Please keep in mind that the preview to the left might not be 100% accurate. Please verify your
                  settings on your actual kiosk.
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row row-editing">
          <h5>Color Theme</h5>
          <div className="row row-editing">
            <div className="col">
              <div
                className={`selectable-image ${colorTheme === 'default' ? 'selected' : ''}`}
                onClick={() => setColorTheme('default')}
              >
                <img src={WhiteMode} />
                <div className="title">Default</div>
              </div>
            </div>
            <div className="col">
              <div
                className={`selectable-image ${colorTheme === 'black' ? 'selected' : ''}`}
                onClick={() => setColorTheme('black')}
              >
                <img src={DarkMode} />
                <div className="title">Black & White</div>
              </div>
            </div>
            {(isAdmin || colorTheme === 'kebaboom') && (
              <>
                <div className="col">
                  <div
                    className={`selectable-image ${colorTheme === 'kebaboom' ? 'selected' : ''}`}
                    onClick={() => setColorTheme('kebaboom')}
                  >
                    <img src={CustomTheme} />
                    <div className="title">Kebaboom</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="row row-editing">
          <h5>Kiosk UI Settings</h5>
          <p> In here you can customize some parts of the user interface of the kiosk</p>

          <p>
            <label>
              <input
                type="checkbox"
                checked={showCouponInput}
                className="filled-in browser-default"
                aria-label="test"
                onChange={() => setShowCouponInput(!showCouponInput)}
              />
              <span>Allow Coupon Code Input</span>
            </label>
          </p>
        </div>
      </div>
      <Button variant="contained" size="large" onClick={handleSave} disabled={saving}>
        Save
      </Button>
    </Stack>
  )
}
