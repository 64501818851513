import { OpenAI } from 'openai'

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
})

export const fetchMenuFromImage = async (base64image: string) => {
  const response = await openai.chat.completions.create({
    model: 'gpt-4-vision-preview',
    temperature: 0,
    max_tokens: 4096,
    messages: [
      {
        role: 'system',
        content:
          "Provide your response as an array of items and categories: Example response: [{ itemType: 'drink', ... }, ...]. Do not write any text before or after that array. Do not add currency sign to the price. RETURN AN ARRAY OF ITEMS ONLY!!!",
      },
      {
        role: 'user',
        content: [
          {
            type: 'text',
            text: `We are trying to get data from an image,
            that displays restaurant menu and convert it to a json with format that we use.
            I will provide image first, and then our json format.
          `,
          },
          {
            type: 'image_url',
            image_url: {
              url: base64image,
            },
          },
          {
            type: 'text',
            text: `
            If there are no options or extras, please provide empty array.
            Note, that Translations type represents:
            type Translations<Keys extends string[]> = {
              [Key in Keys[number]]: Partial<{
                [languageCode in typeof AVAILABLE_LANGUAGES[number]]?: string
              }> & {
                // En is required
                en: string
              }
            }
            Our current menu item json format is like this:
            {
              "translations": Translation<["title", "description"]>
              "itemType": "main" | "side_dish" | "drink"
              "type": "menuItem"
              "price": string
              "isAlcohol": boolean,
              "extras": {
                "translations": Translation<["name"]>
                "price": string
              }[]
              "options": {
                "translations": Translation<["optionName"]>
                "optionList": {
                  "translations": Translation<["name"]>
                  "price": string
                }[],
                // If optionChoiceLimit is not on the image, it is assumed that it is "1", so provide "1" as a string
                "optionChoiceLimit": string
              }[]
            }
            We also have a menu category json format:
            {
              "type": "editableText",
              "translations": Translation<["title", "description"]>
            }
            Note that there is always at leane one category in menu.
            If an item is going after a category in menu list, it means that item belongs to that category.
            Please convert menu from the image to this format.
            `,
          },
        ],
      },
    ],
  })

  return response
}
